<script>
import {deepCopy, deepMerge} from "@/util/objects"
import bjggs from "@/util/bjggs";

export default {
    data() {
        return {
            entity: deepCopy(this.defaultModel)
        }
    },
    props: {
        detail: {
            type: Object,
            required: true,
            validator: (value) => {
                return ['visible', 'id'].every((item) => value.hasOwnProperty(item))
            }
        }
    },
    methods: {
        open() {
            return this.getDetailService(this.detail.id).then((response) => {
                this.entity = deepMerge(this.defaultModel, response.data);
                return response;
            }).catch(reason => {
                this.entity = deepCopy(this.defaultModel);
            })
        },
        close() {
            //关闭
            this.detail.visible = false;
        },
        //是否开启同步
        isSyncOpen() {
            return bjggs.IS_SYNC_OPEN;
        }

    }
}
</script>
