<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="gongHuoShangBM" label="供货商编码" />
            <x-detail-item prop="gongHuoShangMC" label="供货商名称" />
            <x-detail-item prop="lianXiRen" label="联系人" />
            <x-detail-item prop="lianXiRenDH" label="联系人电话" />
            <x-detail-item prop="xiangXiDZ" label="详细地址" />
            <x-detail-item prop="jingYingFS" label="经营方式" />
            <x-detail-item prop="jieSuanFS" label="结算方式" />
            <x-detail-item prop="jieSuanTianShu" label="结算天数" />
            <x-detail-item prop="kouDianBiLi" label="扣点比例" />
            <x-detail-item prop="kaiHuYinXing" label="开户银行" />
            <x-detail-item prop="yinXingKaHao" label="银行卡号" />
            <x-detail-item prop="beiZhu" label="备注" />
            <x-detail-item label="启用/禁用">
                <x-dict-show :code="entity.use" dictType="T_USE"/>
            </x-detail-item>
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/cggl/GongHuoShang";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                gongHuoShangBM: "", // 供货商编码
                gongHuoShangMC: "", // 供货商名称
                lianXiRen: "", // 联系人
                lianXiRenDH: "", // 联系人电话
                xiangXiDZ: "", // 详细地址
                jingYingFS: "", // 经营方式
                jieSuanFS: "", // 结算方式
                jieSuanTianShu: "", // 结算天数
                kouDianBiLi: "", // 扣点比例
                kaiHuYinXing: "", // 开户银行
                yinXingKaHao: "", // 银行卡号
                beiZhu: "", // 备注
                use: "", // 启用/禁用
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>