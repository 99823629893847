<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="供货商编码" >
                <el-input v-model="search.gongHuoShangBM" size="small" />
            </x-search-item>
            <x-search-item label="供货商名称" >
                <el-input v-model="search.gongHuoShangMC" size="small" />
            </x-search-item>
            <x-search-item label="联系人" >
                <el-input v-model="search.lianXiRen" size="small" />
            </x-search-item>
            <x-search-item label="启用状态" >
                <x-selector-one v-model="search.use" size="small" dictType="T_USE"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="gongHuoShangBM" label="供货商编码" />
            <el-table-column width="180" prop="gongHuoShangMC" label="供货商名称" />
            <el-table-column width="180" prop="lianXiRen" label="联系人" />
            <el-table-column width="180" prop="lianXiRenDH" label="联系人电话" />
            <el-table-column width="180" prop="xiangXiDZ" label="详细地址" />
            <el-table-column width="180" label="经营方式">
                <x-dict-show slot-scope="{row}" :code="row.jingYingFS" dictType="T_USER_TYPE" />
            </el-table-column>
            <el-table-column width="180" prop="jieSuanFS" label="结算方式" />
            <el-table-column width="180" prop="jieSuanTianShu" label="结算天数" />
            <el-table-column width="180" prop="kouDianBiLi" label="扣点比例" />
            <el-table-column width="180" prop="kaiHuYinXing" label="开户银行" />
            <el-table-column width="180" prop="yinXingKaHao" label="银行卡号" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" label="启用状态">
                <x-dict-show slot-scope="{row}" :code="row.use" dictType="T_USE" />
            </el-table-column>
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/GongHuoShang";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/cggl/ghs/GongHuoShangDetail";

    export default {
        name: "GongHuoShangQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = service.query;
            return {
                search: {
                    gongHuoShangBM: '',
                    gongHuoShangMC: '',
                    lianXiRen: '',
                    use: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>