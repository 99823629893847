import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/gong-huo-shang/list',
        method: 'post',
        data
    })
}

//获取供货商选择列表  czy
export function selectList(data) {
    return request({
        url: '/gong-huo-shang/get-select-list',
        method: 'post',
        data
    })
}


//添加保存
export function add(data) {
    return request({
        url: '/gong-huo-shang/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/gong-huo-shang/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/gong-huo-shang/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/gong-huo-shang/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/gong-huo-shang/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/gong-huo-shang/delete-batch',
        method: 'post',
        data
    })
}

//启用/禁用
export function use(data) {
    return request({
        url: '/gong-huo-shang/use',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/gong-huo-shang/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'gong-huo-shang-list',
    component: () => import('@/view/cggl/ghs/GongHuoShangList'),
    name: 'GongHuoShangList',
    meta: {title: '供货商', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'gong-huo-shang-query',
    component: () => import('@/view/cggl/ghs/GongHuoShangQuery'),
    name: 'GongHuoShangQuery',
    meta: {title: '供货商查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'gong-huo-shang-remind-list',
    component: () => import('@/view/cggl/ghs/GongHuoShangRemindList'),
    name: 'GongHuoShangRemindList',
    meta: {title: '供货商提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"供货商基础列表","methodUrl":"/gong-huo-shang/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"供货商添加保存","methodUrl":"/gong-huo-shang/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"供货商获取编辑数据","methodUrl":"/gong-huo-shang/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"供货商获取详情数据","methodUrl":"/gong-huo-shang/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"供货商编辑保存","methodUrl":"/gong-huo-shang/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"供货商删除","methodUrl":"/gong-huo-shang/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"供货商启用/禁用","methodUrl":"/gong-huo-shang/use","operateType":"edit","sort":9,"functionClassification":"0","_key":"9"},
{"name":"供货商批量删除","methodUrl":"/gong-huo-shang/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"供货商独立查询","methodUrl":"/gong-huo-shang/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
